import { useState, useEffect } from 'react'
import { toast } from 'sonner'
import { motion } from 'framer-motion'
import { Dialog, DialogBackdrop, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { ChevronLeft, Info, Smartphone, ChevronDown, ChevronUp } from "lucide-react"

import LogoIcon from '../../assets/logo-icon.svg'
import { sendContactRequest } from '../../utils/request'
import { isValidEmail } from '../../utils/util'
import ContactModal from '../ContactModal'
import SuccessModal from '../home/SuccessModal'

const companies = [
    {name: 'DZ', logo: "/dz.webp"},
    {name: 'BusinessMN', logo: "/businessmn.webp"},
    {name: 'Artisan', logo: "/artisan.webp"},
    {name: 'LPS', logo: "/lps.webp"},
]

const features = [
    { id: 'reg1', title: "Цаг бүртгэх 2 сонголттой", desc: "Байршлаар болон байгууллагын Wi-Fi холболтоор цаг бүртгэх сонголттой", icon: '/reg1-icon.svg', extraIcon: '/reg1a-icon.svg', href: '#' },
    { id: 'reg2', title: "Нэмэгдэл/олговрын сонголттой", desc: "Цаг бүртгэх байршил тус бүр дээр ялгаатай нэмэгдэл/олговор тооцох боломжтой", icon: '/reg2-icon.svg', href: '#' },
    { id: 'reg3', title: "Уян хатан тохиргоотой", desc: "Хэдэн ч алба хэлтэс үүсгэж болох ба цаг бүртгэлийн тохиргоог алба хэлтэс бүрийн онцлогт тохируулан хийх боломжтой", icon: '/reg3-icon.svg', href: '#' },
    { id: 'reg4', title: "Ажилтны апп-тай уялдаатай", desc: "Цаг бүртгэлтэй холбоотой бүх модуль ажилтны апп-тай уялдаж ажиллана", icon: '/reg4-icon.svg', href: '#' },
    { id: 'reg5', title: "Ирцийн тайлан гаргана", desc: "Нийт ажилчдын ирцийн тайлан excel файлаар гаргана", icon: '/reg5-icon.svg', href: '#' },
    { id: 'reg6', title: "Засвар, өөрчлөлтийг хялбар хийнэ", desc: "Бүртгэгдсэн цаг засах, ажлын хуваарь, ээлж солих, нэмэгдэл нэмж хасах үйлдлүүдийг хялбар гүйцэтгэнэ", icon: '/reg6-icon.svg', href: '#' },
    { id: 'reg7', title: "Цалин тооцоололтой уялдаж ажиллана", desc: "Цалин тооцоолотой уялдаж ажиллан ажилтны цалинг автоматаар бодно", icon: '/reg7-icon.svg', href: '#' },
]

const faqs = [
    { question: "Цаг бүртгэх 2 сонголттой гэж юу вэ?", answer: "Байршлаар болон байгууллагын Wi-Fi холболтоор цаг бүртгэх сонголттой" },
    { question: "Нэмэгдэл/олговрын сонголттой гэж юу вэ?", answer: "Цаг бүртгэх байршил тус бүр дээр ялгаатай нэмэгдэл/олговор сонгон тооцох боломжтой" },
    { question: "Уян хатан тохиргоотой гэж юу вэ?", answer: "Хэдэн ч алба хэлтэс үүсгэж болох ба алба хэлтэс бүрийн онцлогт тохирсон цаг бүртгэлийн тохиргоо хийх боломжтой" },
    { question: "Ажилтны апп-тай уялдаатай гэж юу вэ?", answer: "Цаг бүртгэлтэй холбоотой бүх модуль ажилтны апп-тай уялдаж ажиллана" },
    { question: "Ирцийн тайлан гаргана гэж юу вэ?", answer: "Нийт ажилчдын ирцийн тайлан excel файлаар гаргана" },
    { question: "Засвар, өөрчлөлтийг хялбар хийнэ гэж юу вэ?", answer: "Бүртгэгдсэн цаг засах, ажлын хуваарь, ээлж солих, нэмэгдэл нэмж хасах үйлдлүүдийг хялбар гүйцэтгэнэ" },
    { question: "Цалин тооцоололтой уялдаж ажиллана гэж юу вэ?", answer: "Цалин тооцоолотой уялдаж ажиллан ажилтны цалинг автоматаар бодно" },
]

export default function AttendancesMain(){
    const [open, setOpen] = useState(false)
    const [allOpen, setAllOpen] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [industry, setIndustry] = useState('')
    const [employeeCount, setEmployeeCount] = useState('')
    const [position, setPosition] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [openSuccess, setOpenSuccess] = useState(false)

    const handleKeyDown = (e) => {
        if(e.key === 'Enter') handleContactRequest()
    }

    const handleContactRequest = async () => {
        if(!companyName) return toast.error('Компанийн нэр хоосон байна')
        if(!industry) return toast.error('Үйл ажиллагааны чиглэл хоосон байна')
        if(!employeeCount) return toast.error('Ажилчдын тоо хоосон байна')
        if(!position) return toast.error('Таны албан тушаал хоосон байна')
        if(!email) return toast.error('Имэйл хаяг хоосон байна')
        if(!isValidEmail(email)) return toast.error('Имэйл хаяг буруу байна')
        setLoading(true)
        try {
            const res = await sendContactRequest(companyName, industry, employeeCount, position, email)
            if(!res) throw new Error('Хүлээлгийн форумыг илгээхэд алдаа гарлаа')
            toast.success('Хүлээлгийн форум амжилттай илгээгдлээ')
            setIsButtonDisabled(true)
            setOpenSuccess(true)
        } catch (error) {
            toast.error('Хүлээлгийн форумыг илгээхэд алдаа гарлаа')
        } finally {
            setLoading(false)
            setIsButtonDisabled(false)
        }
    }
    return (
        <div>
            <motion.header initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="sticky top-0 z-50 backdrop-blur-sm py-4">
                <a href='/' className="px-5 lg:px-[50px] max-w-[1440px] mx-auto flex gap-2 items-center">
                    <ChevronLeft className="w-6 h-6" />
                    <span className='text-base sm:text-xl font-semibold leading-6 sm:leading-7 tracking-[1%]'>Буцах</span>
                </a>
            </motion.header>

            <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto flex flex-col items-center justify-center pt-[100px] sm:pt-[115px] pb-[50px] sm:pb-[100px] gap-[15px] sm:gap-[30px] relative">
                <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                    <div style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-accent to-[#5788e3] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"/>
                </div>
                <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="flex flex-col gap-[5px] text-[26px] sm:text-5xl text-center text-primary leading-8 sm:leading-[58px] tracking-[2%]"><span className='w-full text-center md:text-left text-base sm:text-xl text-[#0C5DD5] leading-6 sm:leading-7 tracking-[1%]'>Цалин тооцоололтой уялдаж ажиллах</span>Цаг бүртгэлийн амар шийдэл</motion.h1>
                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="text-base sm:text-xl text-primary leading-6 sm:leading-7 tracking-[1%]">Энгийн хэрнээ мэргэжлийн</motion.p>
            </div>

            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className='px-5 lg:px-[50px] max-w-[1440px] mx-auto relative overflow-hidden bg-white'>
                <img src='/register-main.webp' className="w-[768px] sm:w-full max-w-none h-full mt-25 mb-15" alt="register-main" />
            </motion.div>

            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="px-5 lg:px-[50px] max-w-[1440px] mx-auto py-[60px] flex flex-col sm:flex-row justify-start items-center gap-[30px] sm:gap-4 bg-white">
                <div className='flex justify-start items-start'>
                    <h1 className="text-base sm:text-lg text-primary !leading-[22px] tracking-[2%] text-center sm:text-start sm:w-[180px]">Манай хэрэглэгчид нэмэгдсээр байна</h1>
                </div>
                
                <div className='logos w-full'>
                    <div className='logos-mobile-slide flex sm:hidden'>
                        { companies.map((company, index) => (
                            <img src={company.logo} key={index} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                    <div className='logos-slide-register hidden sm:flex'>
                        { companies.map((company, index) => (
                            <img src={company.logo} key={index} alt={company.name} className="w-auto h-full object-cover" />
                        )) }
                    </div>
                </div>
            </motion.div> 

            <div className="py-[150px] sm:py-[180px]">
                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[30px] bg-white">
                    <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once:true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цаг бүртгэх 2 сонголттой</motion.h1>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto grid sm:grid-cols-2 mt-[50px] gap-x-28 gap-y-[60px] bg-white">
                    <div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className='flex gap-2 items-center'>
                            <img src='/map-pin-alt.svg' />
                            <h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="text-base sm:text-lg font-semibold leading-6 sm:leading-7 tracking-[1%]">Газрын зураг дээрх байршлаар</h2>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }}>
                            <img src='/register1.webp' className="w-full sm:w-[80%] mt-5" alt="register-1" />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                            <p>Зөвхөн тэмдэглэсэн байршил, тогтоосон радиус руу ажилтан нэвтрэх үед ажилтны апп дээрх Ирлээ товч идэвхжин ажилтан цагаа бүртгүүлэх боломжтой болно</p>
                        </motion.div>
                    </div>

                    <div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className='flex gap-2 items-center'>
                            <img src='/wifi.svg' />        
                            <h2 className="text-base sm:text-lg font-semibold leading-6 sm:leading-7 tracking-[1%]">Байгууллагын Wi-Fi холболтоор</h2>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }}>
                            <img src='/register2.webp' className="w-full sm:w-[80%] mt-5" alt="register-2" />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                            <p>Зөвхөн байгууллагын Wi-Fi д холбогдсон үед ажилтны апп дээрх Ирлээ товч идэвхжин ажилтан цагаа бүртгүүлэх боломжтой болно</p>
                        </motion.div>
                    </div>
                </div>

                <div className='px-5 lg:px-[50px] max-w-[1440px] mx-auto mt-[80px] bg-white'>
                    <div className='border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full'>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className='flex gap-2'>
                            <Info className="h-7 w-7 text-[#18AA0B] flex-shrink-0" />
                            <h2 className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 items-center tracking-[2%]">Цаг бүртгэх 9,999 байршил нэмэх боломжтой</h2>
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="font-normal text-primary text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%] block mt-2.5 ml-7 sm:ml-9">
                            <p>Ажилтан ирсэн, тарсан цагаа бүртгүүлэхээ мартсан тохиолдолд апп-аасаа хүсэлт илгээн засуулах, мөн ажилтны цаг бүртгэлийн задаргаа цонхноос засвар оруулах боломжтой</p>
                        </motion.div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto overflow-hidden grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-[100px] py-[200px] bg-white">
                    <div className='flex flex-col justify-center'>
                        <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center">
                            <img src='/register3.webp' className="w-full mx-auto" alt="register-3" />
                        </motion.div>
                        <div className='flex gap-2 flex-row border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full mt-20'>
                            <Info className="h-7 w-7 text-[#18AA0B] flex-shrink-0" />
                            <motion.h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[2%]">Нэмэгдэл/олговрын үнэлгээг “Үнэлгээ олговрын тохиргоо”-нд оруулна</motion.h2>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center order-first sm:order-last">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цаг бүртгэх байршил тус бүр дээр ялгаатай нэмэгдэл/олговор тооцох боломжтой</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                            <p>Ажилтан нэмэгдэл/олговор сонгогдсон байршилд цагаа бүртгүүлсэн тохиолдолд тухайн нэмэгдэл/олговор ажилтны цалин тооцоололд нэмэгдэж бодогдоно</p>
                        </motion.div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-[100px] bg-white">
                    <div className="flex flex-col justify-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цаг бүртгэлийн уян хатан тохиргоотой</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%] space-y-5">
                            <p>Хэдэн ч алба хэлтэс үүсгэж болох ба цаг бүртгэлийн тохиргоог алба хэлтэс бүрийн онцлогт тохируулан хийх боломжтой</p>
                            <p>Алба хэлтсээр нь тохируулсан тохиргоог ажилтан тус бүрээр өөрчлөх боломжтой</p>
                        </motion.div>
                    </div>
                    <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center relative">
                        <img src='/register4.webp' className="w-full" alt="register-4" />
                    </motion.div>
                </div>

                <div className='bg-background mt-[115px] '>
                    <div className="overflow-hidden py-[60px] sm:py-0 max-w-[1440px] mx-auto">
                        <div className="mx-auto grid grid-cols-1 gap-y-[30px] lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <div className="lg:mx-auto flex items-center">
                                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto">
                                    <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-lg sm:text-2xl text-primary leading-7 sm:leading-[34px] tracking-[1%]">Ажилтны апп дээрх календарь цаг бүртгэлийн тохиргоотой уялдаж ажиллана</motion.p>
                                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[30px] text-lg leading-7 text-primary/75 tracking-[1%] sm:max-w-[542px]">
                                        <p>Хэрэв ажилтан бүртгэгдсэн цагаа засуулах тохиолдолд календарь дээрх цаг засуулах өдөр дээр дарж, гарч ирсэн цонхноос хүсэлт илгээн шийдвэрлүүлнэ</p>
                                    </motion.div>
                                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-[30px] sm:mt-32 space-y-[5px] font-normal text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%]">
                                        <p className='text-[#E7A600]'>Хүсэлт илгээсэн</p>
                                        <p className='text-[#008E4A]'>Хүсэлтийг зөвшөөрсөн </p>
                                        <p className='text-[#BC1818]'>Хүсэлтийг татгалзсан</p>
                                    </motion.div>
                                </div>
                            </div>
                            <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex items-start justify-end lg:order-first">
                                <img src='/register5.webp' alt="register-5" className="w-full max-h-full" />
                            </motion.div>
                        </div>
                    </div>
                    <div className="overflow-hidden sm:pt-[80px] sm:pb-[70px] max-w-[1440px] mx-auto">
                        <div className="mx-auto grid grid-cols-1 gap-y-[40px] sm:gap-x-[50px] sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                            <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="pl-5 lg:pl-0 pb-[60px] sm:pb-0 flex items-center justify-start lg:order-last relative">
                                <img src='/register6.webp' alt="register-6" className="w-[600px] sm:w-[920px] -bottom-0 h-auto max-w-none lg:absolute" />
                            </motion.div>
                            <div className="lg:mx-auto flex items-center order-first">
                                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto">
                                    <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-lg sm:text-2xl text-primary leading-7 sm:leading-[34px] tracking-[1%]">Ажилтнаас ирүүлсэн хүсэлтийг шийдвэрлэхэд хялбар, ойлгомжтой</motion.p>
                                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[30px] space-y-5 text-lg leading-7 tracking-[1%]">
                                        <p className='text-primary/75'>Календарь дээр тухайн ажилтны авч байсан амралт, чөлөө, засуулсан цагууд тэмдэглэгдсэн байх нь таны шийдвэр гаргалтад тусална</p>
                                        <p className='text-primary/75'>Шийдвэрлэсэн хүсэлт ажилтны апп-д мэдэгдлээр очно</p>
                                        <div className='flex gap-[3px] items-center'>
                                            <Smartphone className='w-[22px] h-[22px]'/>
                                            <p onClick={() => setOpen(true)} className='font-semibold text-primary text-base sm:text-lg cursor-pointer leading-6 sm:leading-[22px] tracking-[1%]'>Ажилтны апп-с харъя</p>
                                        </div>
                                    </motion.div>
                                    <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-20 space-y-5 font-normal text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%]">
                                        <div className='flex flex-row gap-2 border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full mt-[55px] bg-white'>
                                            <Info className="h-7 w-7 text-[#18AA0B] flex-shrink-0" />
                                            <motion.h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[2%]">Илүү цагаар ажиллах, цаг засуулах болон ажилтны цаг бүртгэлд нөлөөлөх бүх хүсэлтүүд цалин тооцоололтой уялдаж ажиллана</motion.h2>
                                        </div>
                                    </motion.div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Dialog open={open} onClose={setOpen} className="relative z-50">
                        <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />

                        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex flex-col min-h-full justify-center text-center items-center sm:p-0">
                                <DialogPanel transition className="relative w-full transform overflow-hidden text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-6xl data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 flex items-center justify-center" >
                                    <img src='/register6-open.webp' className="max-w-[90%] max-h-[80vh] object-contain" alt="register6-mobile" />
                                </DialogPanel>

                                <div onClick={() => setOpen(false)} className='text-white font-semibold cursor-pointer rounded-xl border-2 border-white py-2 px-4 mt-4'>
                                    Цонх хаах
                                </div>
                            </div>
                        </div>
                    </Dialog>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-20 sm:gap-[100px] pt-[200px] overflow-hidden bg-white">
                    <motion.div initial={{ opacity: 0, x: 40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center">
                        <img src='/register7.webp' className="w-full mx-auto" alt="register-7" />
                    </motion.div>
                    <div className="flex flex-col justify-center order-first sm:order-last">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Ирцийн тайлан гаргана</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 block max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                            <p>Нийт ажилчдын ирцийн нэгдсэн тайланг сар бүрээр нь шүүж excel хүснэгтээр гаргана</p>
                        </motion.div>
                        {/* <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-7 flex gap-2.5 justify-start items-center">
                            <img src='/excel.svg' alt='excel' className='w-8 h-8' />
                            <p className='text-primary font-semibold text-base sm:text-lg leading-6 sm:leading-7 tracking-[1%]'>Ирцийн тайлан загвар харах</p>
                        </motion.div> */}
                    </div>
                </div>

                <div className="overflow-hidden sm:gap-[100px] py-[200px] sm:pt-[270px] sm:pb-[320px] bg-white">
                    <div className='max-w-[1440px] px-5 lg:px-[50px] mx-auto relative isolate grid grid-cols-1 lg:grid-cols-2 gap-20'>
                        <div className="flex flex-col justify-center">
                            <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цэгцтэй, ойлгомжтой<br/>Цагийн хүснэгттэй </motion.h1>
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-10 sm:max-w-[451px] block space-y-[15px] max-w-lg text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                                <p>Цаг бүртгэлийн дэлгэрэнгүйтэй танилцах</p>
                                <p>Бүртгэгдсэн цаг засах </p>
                                <p>Ажлын хуваарьт өөрчлөлт оруулах </p>
                                <p>Ээлж солих </p>
                                <p>Нэмэгдэл & олговрыг нэмж хасах үйлдлүүдийг хялбар гүйцэтгэнэ</p>
                            </motion.div>
                            <div className='flex gap-2 flex-row border-dashed border-[1px] border-accent rounded-[10px] p-2.5 w-full mt-[55px]'>
                                <Info className="h-7 w-7 text-[#FF00D6] flex-shrink-0" />
                                <motion.h2 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="font-normal text-base sm:text-lg text-primary leading-6 sm:leading-7 tracking-[2%]">Ажилтны ажлын хуваарьт өөрчлөлт оруулах мөн ээлж солиход тухайн ажилтны апп-н календарь дээрх ажлын, амралтын өдрүүд, ажиллах цаг мөн өөрчлөгдөнө <span className='text-[#FF00D6]'>SUPER COOL</span></motion.h2>
                            </div>
                        </div>
                        <motion.div initial={{ opacity: 0, x: -40 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.6, duration: 0.6 }} viewport={{ once: true }} className="flex justify-center items-center relative">
                            <img src='/register8.webp' className="w-full lg:w-[1100px] -left-0 h-auto max-w-none lg:absolute " alt="register-8" />
                        </motion.div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pb-[100px] sm:pb-[210px] bg-white">
                    <div className="flex flex-col justify-center items-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Цаг бүртгэлийн хураангуй</motion.h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-20 gap-[40px]">
                        { features.map((feature, index) => (
                            <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 * (index + 1), duration: 0.4 }} viewport={{ once: true }} key={index} className='bg-white rounded-lg flex flex-col'>
                                <div className='py-[5px]'>
                                    <div className="flex flex-col gap-5 justify-center items-start">
                                        <div className='flex justify-center gap-[15px]'>
                                            <img src={feature.icon} className="w-10 h-10" alt={feature.title} />
                                            {feature.extraIcon ? <img src={feature.extraIcon} className="w-10 h-10" alt={feature.title} /> : null}
                                        </div>
                                        <span className="text-lg sm:text-[22px] text-primary font-semibold leading-6 sm:leading-7 tracking-[1%]">{feature.title}</span>
                                    </div>
                                    <div className="mt-[15px] text-base sm:text-lg text-primary/75 leading-6 sm:leading-7 tracking-[1%]">
                                        { feature.desc }
                                    </div>
                                </div>
                            </motion.div>
                        )) }
                    </div>
                </div>

                <div className='px-5 lg:px-[50px] max-w-[1440px] mx-auto'>
                    <div className="flex flex-col items-center justify-center py-[65px] gap-[20px] border-[1px] border-indicator/10 shadow-sm rounded-[20px] relative overflow-hidden">
                        <div aria-hidden="true" className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                            <div style={{ clipPath: 'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)', }} className="relative left-[calc(85%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-accent to-[#5788e3] opacity-30 sm:left-[calc(85%-30rem)] sm:w-full"/>
                        </div>
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="flex flex-col gap-[5px] text-3xl sm:text-[38px] text-center text-primary leading-8 sm:leading-[48px]"><span className='w-full text-center md:text-left text-base text-[#0C5DD5] leading-5 sm:leading-6 tracking-[2%]'>Цалин тооцоололтой уялдаж ажиллах</span>Цаг бүртгэлийн амар системийг</motion.h1>
                        <motion.div initial={{ opacity: 0, y: 30 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="flex flex-col gap-y-6 sm:items-start gap-x-6 justify-start">
                            <a href="#registerFormAttendance" className='w-full sm:w-[133px] flex justify-center items-center text-base sm:text-lg bg-primary text-white font-semibold rounded-[5px] px-[19px] py-1.5 leading-[18px] sm:leading-[22px] shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary/90 tracking-[1%]'>
                                Бүртгүүлэх
                            </a>
                        </motion.div>
                    </div>
                </div>

                {/* <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[100px] pb-[150px] sm:pt-[210px] sm:pb-[250px]">
                    <div className="flex flex-col justify-center items-center">
                        <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">
                            Түгээмэл асуулт, хариулт
                        </motion.h1>
                    </div>
                    <Disclosure as="div" className="mt-[60px] max-w-2xl mx-auto">
                        {({ open }) => (
                            <>
                                <DisclosureButton as="div" className="flex w-full justify-end px-5 pb-[15px]" onClick={() => setAllOpen(!allOpen)}>
                                    <motion.div className="flex items-center" initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                                        <span className="text-base sm:text-lg text-primary font-semibold leading-[22px]">
                                            {allOpen ? "Бүгдийг нуух" : "Бүгдийг харах"}
                                        </span>
                                        <span className="ml-6 flex h-7 items-center text-primary">
                                            {allOpen ? (
                                                <ChevronUp className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                            ) : (
                                                <ChevronDown className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                            )}
                                        </span>
                                    </motion.div>
                                </DisclosureButton>
                                <motion.dl className="divide-y divide-main/15" initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                                    {faqs.map((faq) => (
                                        <Disclosure as="div" key={faq.question} className="p-5">
                                            {({ open }) => {
                                                if(open === true) setAllOpen(false)
                                                return (
                                                    <>
                                                        <div>
                                                            <DisclosureButton className="flex w-full items-center justify-between text-left text-slate-700">
                                                                <span className="text-base sm:text-lg text-primary font-semibold leading-[22px]">
                                                                    {faq.question}
                                                                </span>
                                                                <span className="ml-6 flex h-7 items-center text-primary">
                                                                    { (open || allOpen) ? (
                                                                        <ChevronUp className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                                                    ) : (
                                                                        <ChevronDown className="h-[22px] w-[22px] text-primary" aria-hidden="true" />
                                                                    )}
                                                                </span>
                                                            </DisclosureButton>
                                                        </div>
                                                        { (open || allOpen) && (
                                                            <div className="mt-[18px] pr-2">
                                                                <p className="text-base sm:text-lg leading-6 sm:leading-7 text-primary/75" dangerouslySetInnerHTML={{__html: faq.answer }}></p>
                                                            </div>
                                                        )}
                                                    </>
                                                )
                                            }}
                                        </Disclosure>
                                    ))}
                                </motion.dl>
                            </>
                        )}
                    </Disclosure>
                </div> */}

                <div className='' id="registerFormAttendance">
                    <ContactModal open={openSuccess} setOpen={setOpenSuccess}>
                        <SuccessModal setOpen={setOpenSuccess}/>
                    </ContactModal>

                    <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[150px] sm:pt-[180px] bg-white">
                        <div className="flex flex-col items-center justify-center text-center">
                            <motion.h1 initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="text-3xl sm:text-[38px] text-primary leading-7 sm:leading-[48px]">Систем турших хүсэлт</motion.h1>
                        </div>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="flex flex-col gap-[45px] max-w-3xl mx-auto w-full mt-[50px]">
                            <div className="flex flex-col justify-center items-center gap-[15px]">
                                <div className="w-full sm:w-1/2">
                                    <span className="text-base sm:text-lg text-primary/75 leading-6">
                                        Компанийн нэр
                                    </span>
                                    <div className="mt-2">
                                        <input id="companyName" name="companyName" type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="input w-full" />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2">
                                    <span className="text-base sm:text-lg text-primary/75 leading-6">
                                        Үйл ажиллагааны чиглэл
                                    </span>
                                    <div className="mt-2">
                                        <input id="industry" name="industry" type="text" value={industry} onChange={(e) => setIndustry(e.target.value)} className="input w-full" />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2 flex justify-start">
                                    <div className="w-1/3">
                                        <span className="text-base sm:text-lg text-primary/75 leading-6">
                                            Ажилчдын тоо
                                        </span>
                                        <div className="mt-2">
                                            <input id="employeeCount" name="employeeCount" type="number" value={employeeCount} onChange={(e) => setEmployeeCount(e.target.value)} className="input w-full"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2">
                                    <span className="text-base sm:text-lg text-primary/75 leading-6">
                                        Таны албан тушаал
                                    </span>
                                    <div className="mt-2">
                                        <input id="position" name="position" type="text" value={position} onChange={(e) => setPosition(e.target.value)} className="input w-full" />
                                    </div>
                                </div>
                                <div className="w-full sm:w-1/2">
                                    <span className="text-base sm:text-lg text-primary/75 leading-6">
                                        Таны имэйл хаяг
                                    </span>
                                    <div className="mt-2">
                                        <input id="email" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} onKeyDown={handleKeyDown} autoComplete='email' className="input w-full" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-2.5 items-center">
                                { loading ? (
                                    <button type="button" className="w-full sm:w-1/2 flex justify-center rounded-[5px] bg-modal px-[68px] py-[7px] text-base sm:text-lg font-semibold text-white leading-6 sm:leading-7 shadow-sm cursor-not-allowed" >
                                        Уншиж байна..
                                    </button>
                                ) : (
                                    <button type="button" onClick={handleContactRequest} disabled={isButtonDisabled} className="w-full sm:w-1/2 flex justify-center items-center rounded-[5px] bg-modal py-2.5 h-[42px] text-base sm:text-lg font-semibold text-white leading-[22px] tracking-[1%] shadow-sm hover:bg-modal/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-modal/90 transition-colors duration-300">
                                        Илгээх
                                    </button>
                                )}
                                <span className="w-full sm:w-1/2 text-sm text-primary/75 font-normal leading-5 tracking-[1%]">
                                    Бид таны и-мэйлийг бусадтай хуваалцахгүй, сурталчилгаанд ашиглахгүй
                                </span>
                            </div>
                        </motion.div>
                    </div>
                </div>

                <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto pt-[150px] sm:pt-[200px] bg-white">
                    <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="flex flex-col items-center justify-center">
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                            <img src={LogoIcon} alt="Logo" className="w-20 h-20" />
                        </motion.div>
                        <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className="mt-[15px] sm:mt-[25px] text-[#14153B] text-center text-3xl sm:text-[38px] leading-10 sm:leading-[48px] tracking-[2%]">AMARHR системийн Цаг бүртгэлтэй<br/>танилцсанд баярлалаа</motion.p>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.9 }} viewport={{ once: true }} className="mt-[30px]">
                            <a href='/' className="flex gap-2 items-center">
                                <ChevronLeft className="w-6 h-6" />
                                <span className='text-base sm:text-xl font-semibold leading-6 sm:leading-7 tracking-[1%]'>Буцах</span>
                            </a>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}