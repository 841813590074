import { motion } from 'framer-motion'
 
export default function Hero() {
    return (
        <div className='relative overflow-hidden bg-white'>
            <div className="px-5 lg:px-[50px] max-w-[1440px] mx-auto relative isolate">
                <svg className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true" >
                    <defs>
                        <pattern id="0787a7c5-978c-4f66-83c7-11c213f99cb7" width={200} height={200} x="50%" y={-1} patternUnits="userSpaceOnUse" >
                            <path d="M.5 200V.5H200" fill="none" />
                        </pattern>
                    </defs>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)" />
                </svg>
                <div className="pb-[116px] sm:pb-20 lg:flex lg:pt-[52px]">
                    <div className="mx-auto max-w-xl sm:mx-0 sm:max-w-[600px] sm:flex-shrink-0 flex flex-col lg:pt-[70px]">
                        <motion.h1 initial={{ opacity: 0, x: -80 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="mt-[100px] text-3xl sm:text-[40px] lg:mt-0 font-semibold text-primary leading-8 sm:leading-[52px] tracking-[2%] inline">
                            Цаг бүртгэх, Цалин тооцоолох ажлыг 99% автоматжуулна
                        </motion.h1>
                        <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.4 }} viewport={{ once: true }} className="mt-[22px] sm:max-w-[521px] text-base sm:text-xl leading-6 sm:leading-[30px] tracking-[2%] text-primary relative">
                            <span>Таны хийсэн тохиргооны дагуу автоматаар ажиллан цаг хэмнэж ажлыг тань хөнгөвчилнө</span>
                            <img src='/sticker1.webp' alt="Sticker Main" className="absolute right-0 top-12 sm:-right-12 sm:top-8 w-[200px] sm:w-[257px] h-auto" />
                        </motion.div>
                        <motion.div initial={{ opacity: 0, y: 30 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }} className="mt-[167px] flex flex-col gap-y-6 sm:items-start gap-x-6 justify-start">
                            <a href="#registerForm" className='w-full flex justify-center font-semibold items-center text-base sm:text-lg bg-primary text-white rounded-[5px] px-[19px] py-1.5 leading-[18px] sm:leading-[22px] sm:w-[133px] shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary/90 tracking-[1%] transition-colors duration-300'>
                                Бүртгүүлэх
                            </a>
                        </motion.div>
                    </div>
                    <div className="mt-[50px] flex max-w-2xl lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-[70px]">
                        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                            <motion.div initial={{ opacity: 0, x: 80 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.2, duration: 1 }} className="relative -m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                                <img src='/main2.webp' alt="Hero Image" width={2432} height={1442} className="w-[600px] sm:w-[1200px] max-w-none h-full" />
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
