import { motion } from 'framer-motion'
import LogoIcon from '../../assets/logo-icon.svg'

export default function Slogan() {
    return (
        <div className="bg-white px-5 lg:px-[50px] max-w-[1440px] mx-auto py-[150px] sm:pt-[180px] sm:pb-[210px]">
            <motion.div initial={{ opacity: 0, y: -10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.2 }} viewport={{ once: true }} className="flex flex-col items-center justify-center">
                <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.6 }} viewport={{ once: true }}>
                    <img src={LogoIcon} alt="Logo" className="w-20 h-20" />
                </motion.div>
                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7 }} viewport={{ once: true }} className="mt-[15px] sm:mt-[25px] text-[#14153B] text-center text-3xl sm:text-[38px] leading-10 sm:leading-[48px] tracking-[2%]">Цаг бүртгэл, Цалин тооцооллын</motion.p>
                <motion.p initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.8 }} viewport={{ once: true }} className="mt-[5px] sm:mt-[15px] text-[#14153B] text-center text-3xl sm:text-[38px] leading-10 sm:leading-[48px] tracking-[2%]">#1 найдвартай систем</motion.p>
                {/* <motion.div initial={{ opacity: 0, y: 10 }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.9 }} viewport={{ once: true }} className="mt-10">
                    <a href="#registerForm" className='w-full font-semibold flex justify-center items-center text-base sm:text-lg bg-primary text-white rounded-[5px] px-[19px] py-1.5 leading-[18px] sm:leading-[22px] sm:w-[133px] shadow-sm hover:bg-primary/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary/90 tracking-[1%] transition-colors duration-300'>
                        Бүртгүүлэх
                    </a>
                </motion.div> */}
            </motion.div>
        </div>
    )
}
